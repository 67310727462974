<template>
  <div
    class="px-1 mb-3 cursor-pointer"
    :class="classCard"
    @click="selectMaterial()"
  >
    <div v-if="openDialougeMaterialShow">
      <MaterialsMaterialDialouge
        v-model="openDialougeMaterialShow"
        :material-id="material.id"
        :first-property-lookup="material.first_property_lookup_value"
      />
    </div>
    <div class="relative">
      <NuxtImg
        provider="cacheIpx"
        preset="modified"
        loading="lazy"
        :src="material.image"
        :alt="material.name"
        width="128"
        height="128"
        sizes="md:256px"
        class="rounded-[14px] w-full object-cover"
      />
      <MaterialsCardButtonFavourite
        v-if="showFavouriteBotton"
        class="absolute ltr:left-1 rtl:right-1 top-1"
        :material="material"
      />

      <div
        v-if="material.is_featured"
        class="absolute ltr:right-0 text-text_alt_color rtl:left-0 top-0 text-xs lg:text-base bg-nav_color ltr:rounded-br-2xl ltr:rounded-tl-2xl rtl:rounded-bl-2xl rtl:rounded-tr-2xl px-1 sm:py-0.5"
      >
        {{ t('featured') }}
      </div>
      <div
        v-if="material.discountRate && store?.price_visible"
        class="absolute bottom-7 ltr:right-0 rtl:left-0 px-1 font-medium text-xs lg:text-base bg-nav_color text-text_alt_color"
      >
        <div>{{ t('discount') }} %{{ parseInt(material.discountRate) }}</div>
      </div>
    </div>
    <div class="relative">
      <div
        v-if="loading"
        class="absolute inset-0 bg-button_color bg-opacity-40 rounded-lg"
      >
        <EStoreLoadingIndicator
          :loading="true"
          color="border-nav_color"
          class="text-nav_color h-full"
        />
      </div>
      <MaterialsCardAction
        :material="material"
        @toggle-click-cart="toggleOpenDialouges"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { Material } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    materialProps: Material
    classCard?: string
    wishlist?: boolean
  }>(),
  {
    classCard: 'customWidth',
    wishlist: false
  }
)

const { materialModel } = useMenuModel()
const material = materialModel(props.materialProps)
if (props.wishlist) {
  material.hasFavoritedByAgent = true
  material.has_favorited = true
}
const toast = useToast()
const { addItem } = useCartStore()
const loading = ref(false)
const openDialougeMaterialShow = ref(false)
const { store } = useDomainState()
const router = useRouter()

const showFavouriteBotton = computed(() => {
  return store.value?.active_package?.package?.support_user_login
})

const { t } = useI18n()
const localePath = useLocalePath()
function selectMaterial() {
  router.push(localePath(useRouteMaterial(material)))
}
function toggleOpenDialouges(event?: Event) {
  if (material.first_property_lookup_value || !material.simple_unit_style) {
    openDialougeMaterialShow.value = true
  } else if (store.value?.cart_visible) {
    addItemLocal(event)
  } else {
    useContactViaWhatsAppForMaterials(material, undefined)
  }
}

async function addItemLocal(event?: Event) {
  event?.preventDefault()
  if (!loading.value) {
    loading.value = true

    if (await addItem(material, material.min_quantity)) {
      toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
        timeout: 1000
      })
    } else {
      toast.error(t('add_failed'), { timeout: 1000 })
    }
    loading.value = false
  }
}
</script>
<style scoped>
.v-icon--size-default.custom-size-star {
  @apply text-[10px]  w-[10px] h-[6px]  !important;
}
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.customWidth {
  width: 46vw;
}
@media (min-width: 650px) {
  .customWidth {
    @apply sm:w-1/3  md:w-[13.5rem] md:mr-3.5;
  }
}
</style>
