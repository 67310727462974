<template>
  <div>
    <div
      v-for="definition in allDefinitions"
      :key="`definition-box-${definition.id}`"
      class="w-full p-0.5 mt-2"
    >
      <div class="flex flex-wrap items-center mx-1 text-sm text-e_store-light">
        <div class="font-semibold leading-1">{{ definition.label }}:</div>
        <div
          v-for="property in definition.propertiesListValues"
          :key="`attribute-box-${property.id}`"
          class="cursor-pointer mx-1"
        >
          <MaterialsShowPropertiesLookupValuesElementValue
            :option-properties-lookup="property.option"
            :is-selected-before="checkIsSelectedBefore(property.value_id)"
            @on-tap-option="togglePropertyLookupValue(definition, property)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  Material,
  PropertiesLookupValue,
  Definition
} from '~/composables/useMenuModel'
const {
  convertListPropertiesLookupToListDefinition,
  removeCircularReferences
} = useMenuModel()
const props = withDefaults(
  defineProps<{
    material: Material
  }>(),
  {}
)
const allDefinitions = ref<Definition[]>([])
const propertiesLookupSelected = ref<PropertiesLookupValue[]>([])
const { materialDefinitions: materialDefinitionsDomain } =
  useMaterialDefinitionsStore()

const materialDefinitionsCached = computed(
  () => materialDefinitionsDomain.value
)
onMounted(() => {
  updateValuesInParent()
  watch(
    () => props.material.id,
    () => {
      preparePropertiesLookupValues()
      reSelectPropertiesOptionsWithNewMaterialAfterChangeMaterial()
    }
  )
})

preparePropertiesLookupValues()
initLookupValues()
function preparePropertiesLookupValues() {
  allDefinitions.value = convertListPropertiesLookupToListDefinition(
    props.material?.propertiesLookupValuesCustom
      ?.propertiesLookupValuesNormal ?? [],
    (obj: any) => obj.property_id,
    materialDefinitionsCached.value
  )
}

function initLookupValues() {
  const propertiesLookupSelectedTemp = [] as PropertiesLookupValue[]
  for (const definition of allDefinitions.value) {
    if (
      definition.propertiesListValues.length &&
      definition.required_for_agent
    ) {
      propertiesLookupSelectedTemp.push(definition.propertiesListValues[0])
    }
  }
  propertiesLookupSelected.value = removeCircularReferences(
    propertiesLookupSelectedTemp
  )
}

function checkIsSelectedBefore(valueId: number): boolean {
  return findIndexProperty(valueId) !== -1
}
function findIndexProperty(valueId: number): number {
  return propertiesLookupSelected.value.findIndex(
    (ele) => ele.value_id === valueId
  )
}
const emit = defineEmits(['properties-lookup-selected'])
function updateValuesInParent() {
  emit('properties-lookup-selected', [...propertiesLookupSelected.value])
}
function togglePropertyLookupValue(
  definition: Definition,
  propertyLookupValue: PropertiesLookupValue
): void {
  if (definition.single_for_agent) {
    selectPropertyLookupForSingleSelectionAgent(definition, propertyLookupValue)
  } else {
    /* إتاحة الاختيار المتعدد للواصفات  */
    selectPropertyLookupForMultiSelectionAgent(definition, propertyLookupValue)
  }

  updateValuesInParent()
}
function selectPropertyLookupForSingleSelectionAgent(
  definition: Definition,
  propertyLookupValue: PropertiesLookupValue
) {
  const indexProperty = findIndexProperty(propertyLookupValue.value_id)
  propertiesLookupSelected.value = propertiesLookupSelected.value.filter(
    (element) => element.property_id !== propertyLookupValue.property_id
  )
  if (definition.required_for_agent || indexProperty === -1) {
    propertiesLookupSelected.value.push(propertyLookupValue)
  }
}
function selectPropertyLookupForMultiSelectionAgent(
  definition: Definition,
  propertyLookupValue: PropertiesLookupValue
) {
  /* إتاحة الاختيار المتعدد للواصفات  */
  const indexProperty = findIndexProperty(propertyLookupValue.value_id)
  if (indexProperty === -1) {
    /* إذا لم تكن مضافة من قبل نضيفها */
    propertiesLookupSelected.value.push(propertyLookupValue)
  } else if (!definition.required_for_agent) {
    /* إذا كانت مضافة من قبل */
    /* ولم يكن التعريف المرتبط بها مطلوب نزيلها */
    propertiesLookupSelected.value.splice(indexProperty, 1)
  } else {
    /* إذا كانت مضافة من قبل */
    /*  وكان التعريف المرتبط بها مطلوب يجب أن نتأكد من أنه يوجد قيم أخرى من نفس التعريف مضافة وإلا  لا يمكن إزالتها */
    const propertiesLookupSelectedSamePropertyId =
      propertiesLookupSelected.value.filter(
        (ele) => ele.property_id === propertyLookupValue.property_id
      )
    if (propertiesLookupSelectedSamePropertyId.length > 1) {
      propertiesLookupSelected.value.splice(indexProperty, 1)
    }
  }
}
function reSelectPropertiesOptionsWithNewMaterialAfterChangeMaterial(): void {
  const newPropertiesLookupSelected: PropertiesLookupValue[] = []

  for (const definition of allDefinitions.value) {
    const newProperty = propertiesLookupSelected.value.find(
      (el) => el.property_id === definition.id
    )

    if (newProperty) {
      // في حال وجود قيم قديمة مختارة إعادة اختيارها
      newPropertiesLookupSelected.push(newProperty)
    } else if (
      definition.propertiesListValues?.length &&
      definition.required_for_agent
    ) {
      newPropertiesLookupSelected.push(definition.propertiesListValues[0])
    }
  }
  propertiesLookupSelected.value = newPropertiesLookupSelected
  updateValuesInParent()
}
</script>
