<template>
  <div class="flex items-center">
    <div class="bg-button_color rounded" @click.prevent="decreaseQuantity">
      <v-icon
        icon="mdi mdi-minus-thick"
        :size="size||24"
        class="block text-text_alt_color cursor-pointer"
        :aria-label="t('increase_item', { name: t('quantity') })"
      />
    </div>

    <div class="mt-1 mx-5 text-xl">
      {{ modelValue }} {{ material.unit || '' }}
    </div>

    <div class="bg-button_color rounded" @click.prevent="increaseQuantity">
      <v-icon
        icon="mdi mdi-plus-thick"
        :size="size||24"
        class="block text-text_alt_color cursor-pointer"
        :aria-label="t('decrease_item', { name: t('quantity') })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const props = defineProps<{
  modelValue: number
  size?: number
  material: Material
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
}>()

const { t } = useI18n()

function decreaseQuantity() {
  const minQuantity = props.material.min_quantity ?? 1
  const step = (props.material.step || 0) > 0 ? props.material.step : 1
  const newQuantityValue = Number((props.modelValue - step).toFixed(2))
  if (newQuantityValue >= minQuantity) {
    updateQuantity(newQuantityValue)
  }
}
function increaseQuantity() {
  const step = (props.material.step || 0) > 0 ? props.material.step : 1
  updateQuantity(props.modelValue + step)
}
function updateQuantity(newQuantityValue: number) {
  emit('update:modelValue', Number(newQuantityValue.toFixed(2)))
}
onMounted(() => {
  emit('update:modelValue', props.material.min_quantity ?? 1)
})
</script>

<style scoped>
.quantity-btn {
  @apply p-1 rounded-lg bg-nav_color text-text_alt_color;
}
</style>
