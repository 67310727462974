<template>
  <div
    class="pb-2 mt-2 cursor-pointer w-full md:w-1/2 xl:w-1/3 md:px-2 border-b"
    @click="selectMaterial()"
  >
    <div v-if="openDialougeMaterialShow">
      <MaterialsMaterialDialouge
        v-model="openDialougeMaterialShow"
        :material-id="material.id"
        :first-property-lookup="material.first_property_lookup_value"
      />
    </div>

    <div class="flex relative">
      <NuxtImg
        provider="cacheIpx"
        preset="modified"
        loading="lazy"
        :src="material.image"
        :alt="material.name"
        width="128"
        height="128"
        sizes="md:256px"
        class="rounded-[14px] w-24 h-24 object-cover mx-1"
      />

      <div
        v-if="material.is_featured"
        class="absolute text-text_alt_color -top-1 text-xs lg:text-base bg-nav_color ltr:rounded-br-2xl ltr:rounded-tl-2xl rtl:rounded-bl-2xl rtl:rounded-tr-2xl px-2 py-1 leading-none shadow"
      >
        {{ t('featured') }}
      </div>

      <div
        v-if="loading"
        class="absolute inset-0 bg-button_color bg-opacity-40 rounded-[14px] mx-1"
      >
        <EStoreLoadingIndicator
          :loading="true"
          color="border-nav_color"
          class="text-nav_color h-full"
        />
      </div>

      <div class="flex flex-col flex-auto justify-around mx-1">
        <div class="flex items-center justify-between">
          <div
            class="font-bold text-base break-words px-0.5 flex-shrink-1 text-text_color"
          >
            {{ material.name }}
          </div>

          <MaterialsCardButtonFavourite
            v-if="showFavouriteBotton"
            :material="material"
          />
        </div>

        <div class="text-sm px-0.5 line-clamp-1 text-text_color">
          {{ convert(material.summary) }}
        </div>

        <div class="flex items-center justify-between">
          <div v-if="store?.price_visible" class="flex text-text_color">
            <div
              :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
              class="font-bold"
            >
              <span v-if="material.discountPrice">
                {{ material.discountPrice }}
              </span>
              <span v-else class="mx-1">{{ material.stringPrice }}</span>
            </div>

            <div
              v-if="material.discountPrice"
              :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
            >
              <small class="line-through text-opacity-90 mx-1">
                {{ material.stringPrice }}
              </small>
            </div>

            <div v-if="material.discountRate && store?.price_visible">
              <small>
                {{ t('discount') }} %{{ parseInt(material.discountRate) }}
              </small>
            </div>
          </div>

          <div class="p-1 text-nav_color">
            <v-icon
              v-if="store?.price_visible && store?.cart_visible"
              icon=" mdi mdi-cart-plus"
              class="custom-size-shopping-cart-domain"
              @click.stop="toggleOpenDialouges"
            />
            <IconsCartWhatsapp
              v-else-if="!store?.cart_visible && !material.link_to_original"
              class="w-8 h-8"
              @click.stop="toggleOpenDialouges"
            />
            <a
              v-else-if="!store?.cart_visible && material.link_to_original"
              :href="material.link_to_original"
              target="_blank"
              @click.stop
            >
              <v-icon icon="mdi mdi-open-in-new" size="24px" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import { convert } from 'html-to-text'
import { useRouteMaterial } from '~/composables/useDomain'
import type { Material } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    materialProps: Material
    wishlist?: boolean
  }>(),
  {
    wishlist: false
  }
)

const { materialModel } = useMenuModel()
const material = materialModel(props.materialProps)
if (props.wishlist) {
  material.hasFavoritedByAgent = true
  material.has_favorited = true
}
const toast = useToast()
const { addItem } = useCartStore()
const loading = ref(false)
const openDialougeMaterialShow = ref(false)
const { store } = useDomainState()
const router = useRouter()
const showFavouriteBotton = computed(() => {
  return store.value?.active_package?.package?.support_user_login
})

const { t } = useI18n()
const localePath = useLocalePath()
function selectMaterial() {
  router.push(localePath(useRouteMaterial(material)))
}
function toggleOpenDialouges(event?: Event) {
  if (material.first_property_lookup_value || !material.simple_unit_style) {
    openDialougeMaterialShow.value = true
  } else if (store.value?.cart_visible) {
    addItemLocal(event)
  } else {
    useContactViaWhatsAppForMaterials(material)
  }
}

async function addItemLocal(event?: Event) {
  event?.preventDefault()
  if (!loading.value) {
    loading.value = true

    if (await addItem(material, material.min_quantity)) {
      toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
        timeout: 1000
      })
    } else {
      toast.error(t('add_failed'), { timeout: 1000 })
    }
    loading.value = false
  }
}
</script>
<style scoped>
.v-icon--size-default.custom-size-star {
  @apply text-[10px]  w-[10px] h-[6px]  !important;
}
.text-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
