<template>
  <div :key="`addons-grid-${material.id}`" class="addons-grid">
    <div
      v-for="(attribute, index) in material.addons"
      :key="`attribute-item-${index}`"
      class="flex items-center m-1 border border-text-200 rounded-md overflow-hidden"
    >
      <div
        v-if="material.store?.cart_visible"
        class="flex-shrink-0 flex flex-col items-center text-text_color px-2 py-0.5"
      >
        <button
          :aria-label="t('increase_item', { name: t('quantity') })"
          :disabled="disableIncreaseItem(attribute.id)"
          @click="editQuantityAddonAttribue(attribute.id, '+')"
        >
          <v-icon
            icon="fa: fa-solid fa-chevron-up"
            :size="12"
            class="block"
            :class="{
              'opacity-50 cursor-not-allowed': disableIncreaseItem(attribute.id)
            }"
          />
        </button>

        <div class="leading-none pt-1">
          {{ addonAttributeSelected[attribute.id].quantity }}
        </div>

        <button
          :aria-label="t('decrease_item', { name: t('quantity') })"
          :disabled="addonAttributeSelected[attribute.id].quantity <= 0"
          @click="editQuantityAddonAttribue(attribute.id, '-')"
        >
          <v-icon
            icon="fa: fa-solid fa-chevron-down"
            :size="12"
            class="block"
            :class="{
              'opacity-50 cursor-not-allowed':
                addonAttributeSelected[attribute.id].quantity <= 0
            }"
          />
        </button>
      </div>

      <div class="flex-shrink-1 p-1 break-all">
        <div class="text-sm flex-wrap text-e_store-light">
          <div class="font-medium leading-1">
            {{ attribute.name }}
          </div>
          <div
            v-if="
              material.store?.price_visible
            "
            class="font-light text-e_store-light leading-1"
          >
            {{ attribute.price }}
          </div>
        </div>
        <div class="text-xs leading-1">
          {{ attribute.desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material, Addons } from '~/composables/useMenuModel'
const props = withDefaults(
  defineProps<{
    material: Material
    addonsAtrribute?: Addons[]
  }>(),
  {
    addonsAtrribute: undefined
  }
)

const emit = defineEmits(['addons-attribute', 'price'])
const addonAttributeSelected = ref<Record<string, Addons>>({})
const { t } = useI18n()

watch(
  () => props.addonsAtrribute || props.material.id,
  (value) => watcherToggle(value)
)

const watcherToggle = (value?: number | Addons[]) => {
  if (typeof value === 'number') {
    reSelectOptionsWithNewMaterials()
  } else {
    fillAddonAttribue()
  }
}
function priceAddons(addonAttribute?: Addons) {
  return addonAttribute?.reduce((total, item) => {
    const price =
      addonAttributeSelected.value[item.addon_id].cost * item.quantity
    return total + price
  }, 0)
}
const reSelectOptionsWithNewMaterials = () => {
  const perviousAddons = { ...addonAttributeSelected.value }
  fillAddonAttribue()
  Object.values(perviousAddons).forEach((addon) => {
    const newAddon = props.material.addons?.find(
      (el: Addons) => el.id === addon.id
    )
    if (newAddon) {
      addonAttributeSelected.value[newAddon.id] = perviousAddons[newAddon.id]
    }
  })
  updateAddons()
}

function updateAddons() {
  const addonsTemp = Object.values(addonAttributeSelected.value)
    .filter((el) => el.quantity > 0)
    .map((el) => ({ addon_id: el.id, quantity: el.quantity }))
  emit('addons-attribute', addonsTemp)
  emit('price', priceAddons(addonsTemp) ?? 0)
}
const fillAddonAttribue = () => {
  addonAttributeSelected.value = {}

  props.material.addons?.forEach((attribute) => {
    addonAttributeSelected.value[attribute.id] = {
      quantity: 0,
      ...attribute
    }
  })

  props.addonsAtrribute?.forEach((attribute) => {
    addonAttributeSelected.value[attribute.id].quantity =
      attribute.pivot?.quantity || 0
  })
}

const editQuantityAddonAttribue = (
  attributeId: string,
  operation: string
) => {
  const operand = operation === '+' ? 1 : -1
  addonAttributeSelected.value[attributeId].quantity += operand

  updateAddons()
}

function disableIncreaseItem(attributeId: string) {
  return (
    addonAttributeSelected.value[attributeId].quantity ===
    addonAttributeSelected.value[attributeId].max_amount
  )
}

fillAddonAttribue()
</script>

<style>
.addons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
</style>
