<template>
  <div class="flex">
    <div v-if="enableText" class="mt-1">
      {{ t('select_value') }}
    </div>
    <div :class="{ 'min-w-[170px] mx-2': enableText }">
      <v-menu v-model="isMenuOpen">
        <template #activator="{ props: propsDropDown }">
          <div
            class="p-2 mb-2 border rounded"
            :class="
              !isMobileOrTablet
                ? 'bg-bg_color hover:bg-nav_color  hover:bg-opacity-10'
                : 'bg-nav_color bg-opacity-10 border-text-color'
            "
          >
            <div
              v-bind="propsDropDown"
              class="cursor-pointer flex justify-between"
            >
              <div class="flex">
                <div
                  :class="{
                    'text-text_color text-opacity-60':
                      !selectedMaterialUnit?.name
                  }"
                >
                  {{ selectedMaterialUnit?.name }}
                </div>
              </div>
              <v-icon icon="mdi mdi-menu-down" :size="24" />
            </div>
          </div>
        </template>
        <v-list class="dropdown-menu">
          <div
            v-for="(unit, index) in props.material.unit_information"
            :key="`unit_information-item ${index}`"
            class="py-1 mx-3 text-center cursor-pointer"
            :class="{ 'bg-bg_color': unit.rate == modelValue?.toString() }"
            @click="updateQuantity(unit.rate)"
          >
            <div>
              {{ unit.name }}
            </div>
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'
const { t } = useI18n()
const props = defineProps<{
  modelValue: number
  material: Material
  enableText?: boolean
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
}>()
const { isMobileOrTablet } = useDevice()
const isMenuOpen = ref<boolean>(false)
const selectedMaterialUnit = computed(() => {
  return props.material.unit_information?.find(
    (el) => parseFloat(el.rate) === props.modelValue
  )
})
function updateQuantity(newQuantityValue: string) {
  emit('update:modelValue', parseFloat(newQuantityValue) || props.modelValue)
  isMenuOpen.value = false
}
onMounted(() => {
  const unitRateOne = props.material.unit_information?.find(
    (el: UnitMaterial) => el.rate === '1'
  )
  updateQuantity(unitRateOne?.rate || props.material.unit_information![0]!.rate)
})
</script>

<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_alt_color mt-2 text-text_color  max-h-48;
}
.border-text-color {
  @apply border-text_alt_color !important;
}
</style>
