<template>
  <div class="flex flex-col w-full p-2 text-e_store-light">
    <div class="flex items-center text-xl font-semibold my-2">
      <h1>{{ material.name }}</h1>
      <div
        v-if="material.is_featured"
        class="mx-2 p-1 bg-text_alt_color rounded-full"
      >
        <v-icon :size="24" class="block text-nav_color">
          <IconsBadgeCheck />
        </v-icon>
      </div>

      <div class="flex-auto"></div>


    </div>

    <EStoreLink
      v-if="material.store"
      to="/"
      class="flex items-center group my-2 ltr:mr-auto rtl:ml-auto"
      :aria-label="t('aria_page_label', { page: material.store.name })"
    >
      <v-icon icon="fa: fa-solid fa-store" :size="20" class="opacity-90" />
      <div class="mx-2 font-medium text-text_color group-hover:underline">
        {{ material.store.name }}
      </div>
    </EStoreLink>

    <div
      v-if="material.store?.price_visible"
      class="flex my-2"
      :class="material.simple_unit_style ? 'items-start' : 'items-center'"
    >
      <v-icon :size="20" class="opacity-90">
        <IconsTagPrice />
      </v-icon>

      <div v-if="material.simple_unit_style" class="mx-2">
        <div
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
          class="text-lg font-medium text-text_color"
        >
          <span v-if="material.discountPrice">
            {{ material.discountPrice }}
          </span>
          <span v-else>{{ material.stringPrice }}</span>
        </div>

        <div
          v-if="material.discountPrice"
          class="mt-0.5 line-through text-e_store-light text-sm"
          :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        >
          {{ material.stringPrice }}
        </div>

        <div
          v-if="material.discountRate"
          dir="ltr"
          class="bg-button_color mt-1 text-text_alt_color py-1 px-2 font-light leading-none rounded-lg"
        >
          -{{ parseInt(material.discountRate) }}%
        </div>
      </div>
      <div
        v-else
        :dir="material.currencyIsRtl ? 'rtl' : 'ltr'"
        class="mx-2 text-lg font-medium text-text_color"
      >
        {{ totalPrice }}
      </div>
    </div>

    <MaterialsShowMarkdownSummery
      v-if="material.summary"
      :content="material.summary"
    />

    <div class="border-b border-text_color opacity-25"></div>

    <!-- Summary -->
    <div v-if="material.extra_attributes?.length" class="my-2">
      <div class="flex items-center">
        <v-icon :size="20" class="opacity-90">
          <IconsViewDetails />
        </v-icon>

        <div class="font-medium text-secondary mx-2">
          {{ t('more_details') }}
        </div>
      </div>

      <div
        v-for="(attribute, index) in material.extra_attributes"
        :key="index"
        class="flex space-x-2 space-x-reverse text-sm text-e_store-light"
      >
        <div class="font-medium">
          {{ attribute.name ? attribute.name + ':' : '' }}
        </div>
        <div class="font-light">{{ attribute.desc || '' }}</div>
      </div>

      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <div v-if="material.properties_lookup_values?.length" class="mt-2">
      <div class="flex items-center">
        <v-icon icon="mdi mdi-select-group" :size="20" class="opacity-90" />
        <div class="font-medium text-secondary mx-2">
          {{ t('optional_specification') }}
        </div>
      </div>

      <MaterialsShowPropertiesLookupValuesNormal
        :material="material"
        @properties-lookup-selected="propertiesLookupSelected = [...$event]"
      />
      <MaterialsShowPropertiesLookupValuesEffectPrices
        :key="`EffectPrices-${material.id}`"
        :material="material"
        @update-material="emit('refresh-page', $event)"
      />
      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <!-- addons -->
    <div v-if="material.addons?.length" class="mt-2">
      <div class="flex items-center">
        <v-icon icon="mdi mdi-view-grid-plus" :size="20" class="opacity-90" />
        <div class="font-medium text-secondary mx-2">
          {{ t('additional_attributes') }}
        </div>
      </div>

      <div class="max-h-[13rem] overflow-y-auto">
        <MaterialsShowAddonsAttribute
          :material="material"
          @addons-attribute="updateSelectedAddons"
          @price="addonAttributePrice = $event"
        />
      </div>

      <div class="border-b border-text_color opacity-25 mt-2"></div>
    </div>

    <div class="my-auto"></div>
    <MaterialsShowButtonCartMobile
      v-if="isMobileOrTablet && !isDialouge"
      v-model="quantity"
      class="fixed w-screen bottom-0 z-40 rtl:translate-x-2 ltr:-translate-x-2"
      :total-price="totalPrice"
      :total-price-with-out-discount="totalPriceWithOutDiscount"
      :material="material"
      :loading="loading"
      @open-dialouges="toggleOpenDialouges"
    />
    <MaterialsShowButtonCartDesktop
      v-else
      v-model="quantity"
      :total-price="totalPrice"
      :is-dialouge="isDialouge"
      :total-price-with-out-discount="totalPriceWithOutDiscount"
      :material="material"
      :loading="loading"
      @open-dialouges="toggleOpenDialouges"
    />
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type {
  Material,
  PropertiesLookupValue
} from '~~/composables/useMenuModel'
const props = defineProps<{ material: Material; isDialouge?: boolean }>()
const emit = defineEmits(['refresh-page', 'added-cart-success'])
const { isMobileOrTablet } = useDevice()
const { toggleAddwithAddonsAttribute } = useCartStore()
const toast = useToast()
const loading = ref(false)
const quantity = ref(1)
const addonsAttributeSelected = ref<[]>([])
const propertiesLookupSelected = ref<PropertiesLookupValue[]>([])
const addonAttributePrice = ref(0)
const { t } = useI18n()
const { wishlist, toggleWishlist } = useWishlist(
  props.material.id,
  props.material.hasFavoritedByAgent
)


function updateSelectedAddons($event: any) {
  addonsAttributeSelected.value = $event
}

async function toggleOpenDialouges(event?: Event) {
  if (props.material.store?.cart_visible) {
    await addItemLocal(event)
  } else {
    await useContactViaWhatsAppForMaterials(
      props.material
    )
  }
}


const totalPrice = computed(() => {
  const price =
    quantity.value * (props.material.discount || props.material.finalPrice)

  return useStringPrice(
    price + addonAttributePrice.value,
    props.material.currencyCode
  )
})

const totalPriceWithOutDiscount = computed(() => {
  if (!props.material.discount) {
    return null
  }

  const price = quantity.value * props.material.finalPrice

  return useStringPrice(
    price + addonAttributePrice.value,
    props.material.currencyCode
  )
})

async function addItemLocal(event?: Event) {
  event?.preventDefault()

  loading.value = true

  if (
    await toggleAddwithAddonsAttribute(
      props.material,
      quantity.value,
      addonsAttributeSelected.value,
      undefined,
      [
        ...propertiesLookupSelected.value,
        ...(props.material.propertiesLookupValuesCustom
          ?.propertiesLookupValuesEffectPricesOriginal ?? [])
      ]
    )
  ) {
    toast.success(t('add_done', { to: t('to'), name: t('cart') }), {
      timeout: 1000
    })
    emit('added-cart-success')
  }
  loading.value = false
}
</script>
